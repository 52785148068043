const colors = {
  gray: [
    "#5A5A5A",
    "#525252",
    "#4B4B4B",
    "#444444",
    "#3E3E3E",
    "#383838",
    "#333333",
    "#2E2E2E",
    "#292929",
    "#252525",
    "#212121",
    "#1E1E1E",
    "#1B1B1B",
  ],
  white: ["#FFFFFF", "#E6E6E6", "#CFCFCF", "#BABABA", "#A7A7A7", "#979797", "#888888"],
  gold: [
    "#EAE7DA",
    "#DED9BD",
    "#D7CE9F",
    "#D5C87E",
    "#DBC75A",
    "#E8CB30",
    "#FFD700",
    "#CFB217",
    "#A99525",
    "#8D7E2D",
    "#766B31",
    "#645C33",
    "#565032",
  ],
};

export default colors;
